/* ==========================================================================
 # Masonry Gallery JS
 # from : https://masonry.desandro.com/options.html
========================================================================== */

window.onload = function() {

  if (window.jQuery) {

    (function($) {

      $('.masonry-gallery').masonry({
        itemSelector: '.gallery-item',
        columnWidth: '.gallery-sizer',
        gutter: '.gutter-sizer',
        percentPosition: true,
      });

    })( jQuery );

  }

};
