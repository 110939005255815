/* ==========================================================================
  main.js
========================================================================== */

/**
 * Related posts slider for post, prestation and realisation
 */
const relatedSlider = document.getElementById("related-slider");

if ( relatedSlider !== null ) {

  document.addEventListener( 'DOMContentLoaded', function () {

   let perPage        = ( relatedSlider.classList.contains('related-slider--prestation') ? 4 : 3 );
   let perPageMobile  = ( relatedSlider.classList.contains('related-slider--prestation') ? 2 : 1 );

   new Splide( relatedSlider, {
     type        : 'slide',
   	 perPage     : perPage,
   	 perMove     : 1,
     gap         : '1.5rem',
     pagination  : false,
     breakpoints: {
      768: {
        perPage:  perPageMobile,
        gap:      '1rem',
        arrows:   false,
        padding: '1.5rem'
      },
     }
   }).mount();

  });

}
