/**
 * Search Modal
 *
 * @source : https://codepen.io/dapacreative/pen/zopKNZ
 */

class SearchModal {

  constructor(btn) {
    this.btn = btn;
    this.modal = document.getElementById(btn.dataset.target);
    this.dismiss = this.modal.querySelector("[data-dismiss='search-modal']");
    this.search = this.modal.querySelector('.search-form__input');
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.btn.addEventListener('click', () => {
      this.toggle();
    });

    this.dismiss.addEventListener('click', () => {
      this.close();
    });

    document.body.addEventListener('keyup', (event) => {
      //If keyboard shortcut is ctrl + s
      if(event.ctrlKey && event.which === 83) {
        this.toggle();
      }
      if(event.which === 27) {
        this.close();
      }
    });

  }

  toggle() {
    this.modal.classList.contains('active') ? this.close() : this.open();
  }

  close() {
    this.modal.classList.remove('active');
    document.body.classList.remove('is-active-modal');
    this.search.blur();
    this.btn.focus();
    this.modal.addEventListener('transitionend', (e) => {
      if(e.target === this.modal && e.propertyName === 'visibility'){
        this.search.value = "";
      }
    });
  }

  open() {
    this.modal.classList.add('active');
    document.body.classList.add('is-active-modal');
    this.modal.addEventListener('transitionend', (e) => {
      if(e.target === this.modal && e.propertyName === 'visibility') {
        setTimeout(() => {
          this.search.focus();
        }, 100);
      }
    });
  }
}

const searchModalBtns = Array.from(document.querySelectorAll("[data-toggle='search-modal']"));

for(let btn of searchModalBtns) {

  const search = new SearchModal(btn);

  search.init();

}
