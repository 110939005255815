/* ==========================================================================
  main.js
========================================================================== */

'use strict';
/* global fitvids, cssVars */

/**
 * Init responsive videos
 */
// fitvids();

/**
 * Init CSS vars ponyfill
 */
cssVars({});

/**
 * Smooth scroll
 */
document.querySelectorAll('a[href^="#"]').forEach(trigger => {
 trigger.onclick = function(e) {
     e.preventDefault();
     let hash = this.getAttribute('href');
     let target = document.querySelector(hash);
     let headerOffset = 106;
     let elementPosition = target.offsetTop;
     let offsetPosition = elementPosition - headerOffset;

     window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
     });
 };
});

/**
 * Customers logos carrousel on home page slider with splideJS
 */

const customersCarrousel = document.getElementById("customer-carrousel");

if ( customersCarrousel !== null ) {

  document.addEventListener( 'DOMContentLoaded', function () {

    new Splide( customersCarrousel, {
      type        : 'slider',
      perPage     : 7,
      perMove     : 1,
      gap         : '1.5rem',
      pagination  : false,
      breakpoints: {
    		768: {
    			destroy: true,
    		},
      }
    }).mount();

  });

}

/**
 * Valeurs horizontally scrollable div on Qui sommes-nous template page
 * @source : https://stackoverflow.com/questions/28576636/mouse-click-and-drag-instead-of-horizontal-scroll-bar-to-view-full-content-of-c
 */

if ( document.querySelectorAll('.valeurs-slider').length ) {

  const slider = document.querySelector('.valeurs-slider');
  let mouseDown = false;
  let startX, scrollLeft;

  let startDragging = function (e) {
    mouseDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  };
  let stopDragging = function (event) {
    mouseDown = false;
  };

  slider.addEventListener('mousemove', (e) => {
    e.preventDefault();
    if(!mouseDown) { return; }
    slider.style.cursor = "grab";
    const x = e.pageX - slider.offsetLeft;
    const scroll = x - startX;
    slider.scrollLeft = scrollLeft - scroll;
  });

  // Add the event listeners
  slider.addEventListener('mousedown', startDragging, false);
  slider.addEventListener('mouseup', stopDragging, false);
  slider.addEventListener('mouseleave', stopDragging, false);

}

/**
 * Témoignages slider on prestations archive page with splideJS
 */

const temoignagesCarrousel = document.getElementById("temoignages-slider");

if ( temoignagesCarrousel !== null ) {

  document.addEventListener( 'DOMContentLoaded', function () {

   new Splide( temoignagesCarrousel, {
     type        : 'slide',
   	 perPage     : 2,
   	 perMove     : 1,
     pagination  : true,
     breakpoints: {
      768: {
        perPage: 1,
        arrows: false
      },
     }
   }).mount();

  });

}

/**
 * Fadein fadeout content on hero home page
 */

window.onload = function() {

  if (window.jQuery) {

    (function($) {

      var heroLogo  = $('#hero-logo'),
          heroTitle = $('#hero-title');

      $(window).on('scroll', function() {

        var st = $(this).scrollTop();

        heroLogo.css({
          'opacity' : 1 - st / 600
        });

        heroTitle.css({
          'opacity' : 0 + st / 1000
        });

      });

    })( jQuery );

  }

};



/**
 * Only on mobile :
 * Modal filter in thematique taxonomy archive
 * @source : https://daily-dev-tips.com/posts/vanilla-javascript-modal-pop-up/
 */
document.addEventListener('DOMContentLoaded', () => {

  const modals = document.querySelectorAll('[data-modal]');

  if ( modals.length > 0 ) {

    modals.forEach(function (trigger) {
      trigger.addEventListener('click', function (event) {
        event.preventDefault();
        const modal = document.getElementById(trigger.dataset.modal);
        modal.classList.add('modal--open');
        const exits = modal.querySelectorAll('.modal-exit');
        exits.forEach(function (exit) {
          exit.addEventListener('click', function (event) {
            event.preventDefault();
            modal.classList.remove('modal--open');
          });
        });
      });
    });

  }

});
